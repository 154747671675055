import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

export default function () {
    const slug = window.location.pathname;
    const co_teaching_lessons = useSelector(state => state.co_teaching_lessons);
    const me = useSelector(state => state.me);

    const renderProjectExtensions = () => {
        return (
            <div className={'mindsets-tabs-menu-item ' + (slug === '/teacher/project-extension' ? 'active' : '')}>
                <Link
                    to={'/teacher/project-extension'}
                    className={'mindsets-tabs-menu-item-link '}
                >Projects</Link>
            </div>
        );
    }

    function renderCoClasses() {
        if (co_teaching_lessons.length > 0) {
            return (
                <div className={'mindsets-tabs-menu-item ' + (slug === '/teacher/co-classes' ? 'active' : '')}>
                    <Link
                        to={'/teacher/co-classes'}
                        className={'mindsets-tabs-menu-item-link '}
                    >Co-teaching Classes</Link>
                </div>
            );
        }

        return null;
    }

    const renderPD = () => {
        if (!me) {
            return null;
        }

        return (
            <div className={'mindsets-tabs-menu-item ' + (slug === '/teacher/pd-courses' ? 'active' : '')}>
                <Link
                    to={'/teacher/pd-courses'}
                    className={'mindsets-tabs-menu-item-link '}
                >PD Courses</Link>
            </div>
        );
    }

    return (
        <div className={'mindsets-tabs'}>
            <div className={'mindsets-tabs-menu-wrapper'}>
                <div className={'mindsets-tabs-menu-container'}>
                    <div className={'mindsets-tabs-menu'}>

                        <div className={'mindsets-tabs-menu-item ' + (slug === '/teacher/home' ? 'active' : '')}>
                            <Link
                                to={'/teacher/home'}
                                className={'mindsets-tabs-menu-item-link '}
                            >Challenges</Link>
                        </div>

                        {renderProjectExtensions()}

                        {renderPD()}

                        <div className={'mindsets-tabs-menu-item ' + (slug === '/teacher/classes' ? 'active' : '')}>
                            <Link
                                to={'/teacher/classes'}
                                className={'mindsets-tabs-menu-item-link '}
                            >My Classes</Link>
                        </div>

                        {renderCoClasses()}
                    </div>
                </div>
            </div>
        </div>
    );
}
