import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {debounce, get} from 'lodash';
import MindsetsJsSDK, {ErrorMessageAction, LoadingAction} from "mindsets-js-sdk";

import {storeAnswers} from './utils';
import analytic from 'utils/Analytic';
import {SetStudentStreamCurrentIndexAction} from "../../../../actions/stream_actions";


function ButtonNext({getRefComponents}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stream = useSelector(state => state.stream);
    const current_index = useSelector(state => state.student_stream_current_index);
    const challenge_stream_entities = useSelector(state => state.challenge_version_stream_entities);
    const stream_entities_responses = useSelector(state => state.student_stream_stream_entities_responses);
    const [enable, setEnable] = useState(true);
    const stream_entities = stream_entities_responses.map((se, i) => {
        const challenge_se = get(challenge_stream_entities, i)
        return {
            ...challenge_se,
            ...se
        }
    });
    const stream_entity = get(stream_entities, current_index);

    function onSelectCard(current_index) {
        dispatch(SetStudentStreamCurrentIndexAction(current_index));
        window.scrollTo(0, 0);
    }

    function isLatest() {
        return current_index === stream.index;
    }

    const onClick = debounce(() => {
        if (!enable) {
            return
        }

        return Promise.resolve()
            .then(() => setEnable(false))
            .then(() => analytic.log('progressToolBarNext', {
                stream_id: stream.id,
                current_index,
                lesson_id: stream.lesson_id,
                stream_entity_id: stream_entity.id,
            }))
            .then(() => storeAnswers(getRefComponents(), {
                callback: () => {
                    if (!isLatest()) {
                        return onSelectCard(current_index + 1)
                    } else if (isLatest()) {
                        return Promise.resolve()
                            .then(() => dispatch(LoadingAction(true)))
                            .then(() => MindsetsJsSDK().Api.stream.act(stream.id, {
                                index: stream.index,
                                stream_entity_id: stream_entity.id
                            }));
                    }
                }
            }))
            .catch(error => {
                // This is to stop teacher progressing the student stream.
                // TODO Remove this after we have the teacher student stream page.
                if (error === 'Stream not found.') {
                    dispatch(ErrorMessageAction('This is the latest progress of this student.'));
                    return;
                }
                dispatch(ErrorMessageAction(error));
            })
            .then(() => setEnable(true));
    }, 200, {
        leading: false,
        trailing: true
    });

    function getButtonLabel() {
        const {complete_index} = stream.challenge_version;
        switch (true) {
            case current_index === 0:
                return `START`;
            default:
                return `NEXT`;
        }
    }

    function renderHomeButton() {
        const onClick = () => {
            navigate('/');
        }

        return (
            <div className="pd-tool-bar-button btn btn-large btn-challenge"
                 onClick={onClick}>
                <i className="fas fa-home"/>
            </div>
        );
    }

    function renderNextButton() {
        return (
            <div
                className="pd-tool-bar-button btn btn-large btn-challenge"
                onClick={onClick}
            >
                <span>{getButtonLabel()}</span>
                <i className="fas fa-angle-right"/>
            </div>
        );
    }

    if (current_index === stream.challenge_version.final_index) {
        return null;
    } else {
        return renderNextButton();
    }
}

ButtonNext.propTypes = {
    getRefComponents: PropTypes.func.isRequired
}

export default ButtonNext
