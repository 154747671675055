import React, {useEffect, useState} from 'react';
import MindsetsJsSDK from "mindsets-js-sdk";
import {groupBy, sortBy, filter, map} from 'lodash';

import HomeMenu from "common/HomeMenu";
import ActiveChallenge from './ActiveChallenge';

import "./pd_lib.scss";

export default function () {

    const [challenges, setChallenges] = useState([]);

    useEffect(() => {
        MindsetsJsSDK().API.get(`professional_development/challenges`).then((response) => {
            // console.log('response', response);
            setChallenges(response.items);
        });
    }, []);

    const getGroupTitle = (groupCode) => {
        switch(groupCode) {
            case 'tpd':
                return '';
            case 'tpdbi':
                return 'Teaching from Big Ideas';
            case 'tpdme':
                return 'Motivating and engaging learners';
            case 'tpdmll':
                return 'Supporting Multilingual Learners (MLL) students in math';
            case 'tpdmt':
                return 'Mathematics as tools for solving authentic problems in authentic contexts';
            case 'tpdsb':
                return 'Creating a sense of belonging in the math classroom';
            default:
                return 'Others';
        }
    }

    const getGroupOrder = (groupCode) => {
        switch(groupCode) {
            case 'tpd':
                return 1;
            case 'tpdbi':
                return 2;
            case 'tpdme':
                return 3;
            case 'tpdmll':
                return 4;
            case 'tpdmt':
                return 5;
            case 'tpdsb':
                return 6;
            default:
                return 100;
        }
    }
    
    const groupChallenges = () => {
        const filtered = filter(challenges, (challenge_version) => challenge_version.challenge_code.code != 'tpd1');
        const sorted = sortBy(filtered, (challenge_version) => getGroupOrder(challenge_version.challenge_code.code.slice(0, -1)));
        const grouped = groupBy(sorted, (challenge_version) => {
            return challenge_version.challenge_code.code.slice(0, -1);
        });
        return grouped;
    }

    const renderChallenges = () => {
        const groupedChallenges = groupChallenges();
        return map(groupedChallenges, (groupChallenges, groupCode) => {
            return (
                <div className="section" key={groupCode}>
                    <h4 className="header-new">
                        {getGroupTitle(groupCode)}
                    </h4>
                    <div className="flex-row flex-wrap flex-gap-20">
                        {
                            groupChallenges.map((challenge_version) => {
                                return (
                                    <ActiveChallenge
                                        key={challenge_version.id}
                                        challenge_version={challenge_version}
                                    />
                                );
                            })
                        }
                    </div>
                    <div className="divider"/>
                </div>
            );
        });
    }

    return (
        <div id="professional-development-library">
            <div id="teacher-dashboard">
                <div className="container">
                    <div className="section">
                        <HomeMenu/>
                    </div>

                    <div>
                        {renderChallenges()}
                    </div>
                </div>
            </div>
        </div>
    );
}