import React from 'react';
import {useSelector} from "react-redux";

import analytic from 'utils/Analytic';

export default function ({message, feature_type, show_price_link}) {

    const me = useSelector(state => state.me);

    const renderContentMessage = () => {
        if (message) {
            return (
                <div className="section">
                    {message}
                </div>
            );
        }

        switch (feature_type) {
            case 'create_class_code':
                return (
                    <div className="section">
                        {`Upgrade to a paid membership to create a Mindsets Class Code.`}
                    </div>
                );
            case 'teacher_guide':
                return (
                    <div className="section">
                        {`Upgrade to a paid membership to unlock this teacher guide.`}
                    </div>
                );
            case 'challenge_preview':
                return (
                    <div className="section">
                        {`Upgrade to a paid membership to continue previewing.`}
                    </div>
                );
            case 'challenge_resource':
                return (
                    <div className="section">
                        {`Upgrade to a paid membership to unlock this resource.`}
                    </div>
                );
            case 'project_extension':
            case 'pd_course':
                return (
                    <div className="section">
                        {`Contact hello@mindsets.com to add on.`}
                    </div>
                );
            default:
                return (
                    <div className="section">
                        {`Upgrade to a paid membership to unlock this feature.`}
                    </div>
                );
        }
    }

    const renderPricingMessage = () => {
        if (!show_price_link) {
            return null;
        }

        if (me.subscription_status.only_individual_subscription) {
            return null;
        }

        const onClick = (e) => {
            e.stopPropagation();
            analytic.log('viewPricingFromFeatureLock')
            return true
        }
        
        return (
            <div>
                <a href={process.env.PUBLIC_PRICING_URL} target="_blank" onClick={onClick}>
                    {`View memberships and request a quote.`}
                </a>
            </div>
        )
    }

    return (
        <div>
            {renderContentMessage()}
            {renderPricingMessage()}
        </div>
    );
}