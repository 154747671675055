import React from 'react';
import MindsetsJsSDK, {LoadingAction} from "mindsets-js-sdk";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {get, truncate, throttle} from 'lodash';
import moment from 'moment';

import FeatureLock from 'common/membership/FeatureLock';
import {processStringContent} from 'utils/content';
import analytic from 'utils/Analytic';
import {getChallengeHeroThumb} from 'utils/cloud';

function ActiveChallenge({challenge_version, noPreview = false}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let me = useSelector(state => state.me);

    if (!me) {
        me = {
            pd_subscribed: false,
            curriculum_id: 1,
            curriculum: {
                is_code_shown: true,
                grade_term: 'grade'
            }
        }
    }

    const thumb_url = getChallengeHeroThumb(challenge_version.challenge_code.code);

    const toStart = (e) => {
        e.preventDefault();

        if (noPreview) {
            return false;
        }

        if (!!challenge_version.challenge_code.is_free || !!me.pd_subscribed || !!me.is_dashboard_super_admin) {
            // TODO: put pd access checking in userCan function
        } else {
            return false;
        }

        dispatch(LoadingAction(true));

        const pd_class_code_key = `PD_CLASS_CODE_${challenge_version.challenge_code.code}`.toUpperCase();
        MindsetsJsSDK().Api.setting.show(pd_class_code_key).then((response) => {
            const pd_class_code = response.setting.value;
            if (pd_class_code) {
                return MindsetsJsSDK().Api.lesson
                    .joinPD(pd_class_code)
                    .then(response => navigate('/pd/' + response.stream_code))
                    .catch(error => console.error(error));
            } else {
                console.error('PD class code is empty.')
            }
        }).catch((error) => {
            console.error(error);
        }).then(() => dispatch(LoadingAction(false)));
    }

    const toStartThrottle = throttle(toStart, 2000, {
        leading: true,
        trailing: false
    });

    function renderChallengeName() {
        const name_array = challenge_version.name.replace(': ', ':: ').split(': ');
        return (
            <div className="challenge-title">
                {get(name_array, 0, '')}
                <br/>
                {get(name_array, 1, '')}
            </div>
        );
    }

    function renderNewLabel() {
        const is_new = moment.unix(challenge_version.challenge_code.created_at).isAfter(moment().add(-90, 'day'));
        if (is_new) {
            return (
                <div className="challenge-new-label z-depth-2" style={{color: challenge_version.challenge_code.color}}>
                    {`* NEW *`}
                </div>
            );
        }
    }

    function renderHover() {
        if (noPreview) {
            return null;
        }

        return (
            <div className="challenge-hover">
                <FeatureLock
                    enable={!!challenge_version.challenge_code.is_free || !!me.pd_subscribed || !!me.is_dashboard_super_admin}
                    title={`Mindsets! PD Course`}
                    feature_type={`pd_course`}
                >
                    <button className="btn btn-large btn-on-dark">
                        {`Start`}
                    </button>
                </FeatureLock>
            </div>
        );
    }

    return (
        <div className="active-challenge" onClick={toStartThrottle}>
            <div className="challenge-triangle"
                 style={{borderBottomColor: challenge_version.challenge_code.color}}>&nbsp;</div>
            {renderNewLabel()}
            <div className="challenge-container"
                 style={{backgroundImage: `url(${thumb_url})`}}>
                <div className="challenge-content-container"
                     style={{backgroundColor: challenge_version.challenge_code.color}}>
                    {renderChallengeName()}

                    <div className="challenge-description">
                        {
                            truncate(
                                processStringContent(challenge_version.description, {
                                    convert_html: false,
                                    convert_math: false
                                }),
                                {length: 140, omission: '...'}
                            )
                        }
                    </div>

                    <div className="flex-row flex-gap-5">
                        <div><strong>{`Estimated time:`}</strong></div>
                        <div>{challenge_version.estimated_time} {`minutes`}</div>
                    </div>
                </div>
            </div>

            {renderHover()}
        </div>
    );
}

export default ActiveChallenge;
