import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {debounce} from 'lodash';

import {SetStudentStreamCurrentIndexAction} from "../../../../actions/stream_actions";


function ButtonBack({}) {
    const dispatch = useDispatch();
    const current_index = useSelector(state => state.student_stream_current_index);

    const onClick = debounce(() => {
        if (current_index > 0) {
            dispatch(SetStudentStreamCurrentIndexAction(current_index - 1));
            window.scrollTo(0, 0);
        }
    }, 200, {
        leading: false,
        trailing: true
    });

    function renderBackButton() {
        return (
            <div
                className="btn btn-large btn-grey"
                onClick={onClick}
            >
                <i className="fas fa-angle-left"/>
                <span>Back</span>
            </div>
        );
    }

    if (current_index === 0) {
        return null;
    } else {
        return renderBackButton();
    }
}

export default ButtonBack
